<template>
    <div class="searchBox">
        <div>
            <!-- 输入红包编号 -->
            <el-input class="form_item" v-model="params_search.receive_no" :placeholder="$t('redenvelopenumber')" :clearable='true' size='small'  style="max-width:240px"></el-input>
            <!-- 输入领取人姓名 -->
            <el-input class="form_item" v-model="params_search.user_name" :placeholder="$t('EnterLQname')" :clearable='true' size='small'  style="max-width:240px"></el-input>
            <!-- 输入公司名称 -->
            <el-input class="form_item" v-model="params_search.company_name" :placeholder="$t('Enter_company_name')" :clearable='true' size='small'  style="max-width:240px"></el-input>
            <el-input class="form_item" v-model="email_tel" :placeholder="placeHolder" size='small' :clearable='true' style="max-width:260px">
                <el-select v-model="selectType" slot="prepend" style="width:100px">
                  <el-option :label="$t('email')" value="1"></el-option>
                  <el-option :label="$t('phone')" value="2"></el-option>
                </el-select>
            </el-input>
        </div>
        <div>
            <el-button type="primary" size="small" @click="commponentSearch">{{$t('search')}}</el-button>
            <el-button type="default" size="small" @click="commponentReset">{{$t('reset')}}</el-button>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      selectType:"1",
      placeHolder:this.$t('enter_email'),
      email_tel:"",
      params_search: {
        receive_no:"",
        user_name:"",
        company_name:"",
        email:"",
        mobile:""
      }
    };
  },
  watch:{
    selectType(value){
      if(value=='1'){
        this.placeHolder=this.$t('enter_email')
      }else{
        this.placeHolder=this.$t('Enter_mobile')
      }
    }
  },
  methods: {
    commponentSearch() {
      if(this.selectType==1){
        this.params_search.email=this.email_tel
        this.params_search.mobile=""
      }else{
        this.params_search.mobile=this.email_tel
        this.params_search.email=""
      }
      this.$emit("commponentSearch", this.params_search);
    },
    commponentReset() {
      this.email_tel=""
      this.params_search={
        receive_no:"",
        user_name:"",
        company_name:"",
        email:"",
        mobile:""
      }
    },
  },
};
</script>
<style lang="less" scoped>
.searchBox {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding:0 12px;
  div:first-child {
    width: 1030px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .form_item {
      margin-right: 12px;
    }
  }
  div:nth-child(2) {
    min-width:120px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
}
</style>