<template>
  <div>
    <el-popover
        placement="top-start"
        width="416"
        trigger="click"
        v-model="p_show"
        popper-class="mymarkanappointment"
        @show="show"
        @hide='hide'
    >
      <userMsg :item="item"/>
      <span slot="reference" class="mainColor cursor">{{  user_name_en | priorFormat(user_name_zh, LOCALE)  }}</span>
    </el-popover>
  </div>
</template>
<script>
import userMsg from "~/baseComponents/card/userMsg"
import { priorFormat } from "@/basePlugins/filters";
export default {
  props: {
    user_name_zh:[String],
    user_name_en:[String],
    user_id:[String,Number],
  },
  components:{
    userMsg
  },
  data() {
    return{
      item:{},
      p_show:false,
    }
  },
  methods:{
    async show(){
      let params={
        user_id:this.user_id,// 名片信息用户id
        followed_user_id:this.USER_INFO.id//登录用户id, 查看自己的名片不用传
      }
      let result = await this.$store.dispatch("baseConsole/getCollectionDetail",params);
      let item=result.data
      this.item={
        name:priorFormat(item.user_name_en,item.user_name_zh,this.LOCALE),
        company:priorFormat(item.company_name_en,item.company_name_zh,this.LOCALE),
        address:priorFormat(item.address_en,item.address_zh,this.LOCALE),
        job:priorFormat(item.job_title_en,item.job_title_zh,this.LOCALE),
        country:item.country_id,
        city:item.city,
        mobile:item.mobile,
        email:item.email,
        industry:item.industry,
        region_en:item.region_en,
        region_en:item.region_zh,
        avatar:item.avatar
      }
    },
    hide(){
      
    }
  },
};
</script>
<style lang="less" scoped>

</style>