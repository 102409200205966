<template>
    <div style="height:100%" class="dramerbox">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" :label-width="LOCALE=='zh'?'120px':'150px'"  style="height:100%" class="demo-ruleForm" >
            <template v-if="LOCALE=='zh'">
                <!-- 红包中文主题 -->
                <el-form-item :label="$t('redName')" prop="topic_zh"   >
                    <el-input v-model="ruleForm.topic_zh" maxlength="32" show-word-limit size="small" :readonly="drawerDisabled" :placeholder="$t('Red_Name_zh')" ></el-input>
                </el-form-item>
                <!-- 红包英文主题 -->
                <el-form-item  prop='topic_en' >
                    <el-input v-model="ruleForm.topic_en"  size="small" :readonly="drawerDisabled" :placeholder="$t('Red_Name_en')"></el-input>
                </el-form-item>
                <p class="tips">*{{ $t("oneMustInter") }}</p>
            </template>
            <template v-if="LOCALE=='en'">
                <!-- 红包中文主题 -->
                <el-form-item :label="$t('redName')" prop="topic_zh"   >
                   <el-input v-model="ruleForm.topic_en"  size="small" :readonly="drawerDisabled" :placeholder="$t('Red_Name_en')"></el-input>
                </el-form-item>
                <!-- 红包英文主题 -->
                <el-form-item  prop='topic_en' >
                    <el-input v-model="ruleForm.topic_zh" maxlength="32" show-word-limit size="small" :readonly="drawerDisabled" :placeholder="$t('Red_Name_zh')" ></el-input>
                </el-form-item>
                <p class="tips" :class="{tips_padding:LOCALE=='en'}">*{{ $t("oneMustInter") }}</p>
            </template>
          
            
            <!-- 发放时间 -->
            <el-form-item :label="$t('Issuance_time')" required>
                <div class="timePicker">
                    <el-form-item prop="start_time" class="pick_item_width">
                        <el-date-picker
                            :readonly="drawerDisabled"
                            type="datetime"
                            :placeholder="$t('Starttime')"
                            :picker-options="pickerOptions"
                            format="yyyy-MM-dd HH:mm"
                            v-model="ruleForm.start_time"
                            size="small"
                            style="width: 100%"
                        ></el-date-picker>
                    </el-form-item>
                    <div>-</div>
                    <el-form-item prop="end_time" class="pick_item_width">
                        <el-date-picker
                            :readonly="drawerDisabled"
                            type="datetime"
                            :placeholder="$t('endTime')"
                            :picker-options="pickerOptions"
                            format="yyyy-MM-dd HH:mm"
                            v-model="ruleForm.end_time"
                            size="small"
                            style="width: 100%"
                        ></el-date-picker>
                    </el-form-item>
                </div>
            </el-form-item>
            <!-- 红包个数 -->
            <el-form-item :label="$t('quantity2')" prop="total_num">
                <el-input v-model.number="ruleForm.total_num" :placeholder="$t('Enter_number_red_envelopes')" oninput="value=(parseInt((value=value.replace(/\D/g,''))==''?'':value,10))" min="1" :readonly="drawerDisabled" type="number" size='small'></el-input>
            </el-form-item>
            <!-- 红包类型 -->
            <el-form-item :label="$t('Red_envelope_type')" required >
                <div>
                    <el-row :gutter="10"  type='flex' >
                        <el-col :span="9">
                            <el-form-item >
                                <el-radio v-model="ruleForm.envelope_type" :readonly="drawerDisabled" label="1">{{$t('Lucky_red_envelopes')}}</el-radio>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="total_meney_1">
                                <el-input v-model="ruleForm.total_meney_1" oninput="value=(parseInt((value=value.replace(/\D/g,''))==''?'':value,10))" :class="{'borderRed':showRed_border1 }" min="1" :placeholder="$t('enter_total_acount')" @blur="inputBlur1($event)" :disabled="drawerDisabled || ruleForm.envelope_type!=1 " size='small' type="number"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">{{$t('rmb_yuan')}}</el-col>
                    </el-row>
                    <el-row :gutter="10" type='flex' >
                        <el-col :span="9">
                            <el-form-item >
                                <el-radio :disabled="drawerDisabled" v-model="ruleForm.envelope_type" label="2">{{$t('Ordinary_red_envelopes')}}</el-radio>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="total_meney_2">
                                <el-input :disabled="drawerDisabled || ruleForm.envelope_type!=2 " min="1" oninput="value=(parseInt((value=value.replace(/\D/g,''))==''?'':value,10))" :placeholder="$t('enter_one_acount')"  max="1000" @blur="inputBlur2($event)" :class="{'borderRed':showRed_border2 }" v-model="ruleForm.total_meney_2" size='small' type="number"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">{{$t('rmb_yuan')}}</el-col>
                    </el-row>
                </div>
            </el-form-item>
            <p class="tips" :class="{tips_padding:LOCALE=='en'}">*{{ $t("less_than_1") }}</p>
            <!-- 总金额 -->
            <el-form-item :label="$t('Total_amount')">
                <span >￥ {{ ruleForm.total_amount|myFixed }}</span>
            </el-form-item>
            <!-- 限领次数 -->
            <el-form-item :label="$t('Limited')">
                <!-- 默认领取一次，收藏、评价展台可增加一次领取 -->
                <span class="mainColor">{{$t('Limited_times')}}</span>
            </el-form-item>
        </el-form>
        <div class="clearfix drawerFooter">
            <div style="text-align: right;" v-if="!drawerDisabled">
                <!-- 取消 -->
                <el-button style="width: 80px"   @click="resetForm('ruleForm')"  size='small'>{{$t('cancel')}}</el-button>
                <el-button style="width: 80px"  type="primary" @click="submitForm('ruleForm')" size='small' >{{$t('save')}}</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        related_id:[String, Number],
        drawerValue:[Object],
        drawerDisabled:[Boolean],
        drawerType:[String]
    },
    data(){
        return{
            showRed_border1:false,
            showRed_border2:false,
            ruleForm: {
                topic_zh:this.drawerValue.topic_zh,
                topic_en:this.drawerValue.topic_en,
                start_time:this.drawerValue.start_time?this.drawerValue.start_time*1000:"",
                end_time:this.drawerValue.end_time?this.drawerValue.end_time*1000:"",
                envelope_type:this.drawerValue.envelope_type.toString()||'1', //红包类型: 1-拼手气红包，2-普通红包
                total_num:this.drawerValue.total_num,   //发放总量
                total_amount:this.drawerValue.total_amount/100,//发放总金额
                currency:this.drawerValue.currency, //币种: 1-人民币，2-美元
                limit_receive_num:this.drawerValue.limit_receive_num,//限领次数
                is_abort:this.drawerValue.is_abort,//是否已终止：0-否，1-是
                meeting_id:this.$store.state.meeting_id,
                limit_receive_num:"1",
                total_meney_1:"",
                total_meney_2:"",
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
            rules: {
                topic_en: {
                    validator: async (rule, value, callback) => {
                        if (value == "" && !this.ruleForm.topic_zh) {
                        callback(new Error(" "));
                        }
                    },
                    trigger: "blur",
                    required: true,
                },
                topic_zh: {
                    validator: async (rule, value, callback) => {
                        if (value == "" && !this.ruleForm.topic_en) {
                        callback(new Error(" "));
                        }
                    },
                    trigger: "blur",
                    required: true,
                },
                start_time: [
                    { required: true, message: " ", trigger: 'blur' }
                ],
                end_time: [
                    { required: true, message: " ", trigger: 'blur' }
                ],
                envelope_type: [
                    { required: true, message: ' ', trigger: 'blur' }
                ],
                total_num: [ 
                    { 
                        validator: async (rule, value, callback) => {
                            if (!value) {
                                return callback(new Error(' '));
                            }
                            if (value < 1) {
                                callback(new Error(''));
                            }if(value%1!=0){
                                callback(new Error(''));
                            }{
                                callback();
                            }
                        },
                        message: ' ',
                        required: true,
                        trigger: 'blur' 
                    }
                ],
                total_amount: [
                    { required: true, message: ' ', trigger: 'blur' }
                ],
            }
        }
    },
    mounted(){
        let start_time = this.MEETING_INFO.start_time;
        let end_time = this.MEETING_INFO.end_time;
        this.pickerOptions = {
            disabledDate(time) {
                return (
                    moment(time).unix() < start_time || moment(time).unix() > end_time
                );
            },
        };
        if(this.ruleForm.envelope_type==1){
            this.ruleForm.total_meney_1=this.ruleForm.total_amount||""
        }else{
            this.ruleForm.total_meney_2=this.ruleForm.total_amount/this.drawerValue.total_num
        }

    },
    watch:{
        "ruleForm.envelope_type"(value){
            if(value==1){
                this.ruleForm.total_meney_2=""
                this.showRed_border2=false
            }else{
                this.ruleForm.total_meney_1=""
                this.showRed_border1=false
            }
        },
        "ruleForm.total_meney_1"(value){
            this.ruleForm.total_amount=value
            if(!value){
                 this.showRed_border1=false
            }
        },
        "ruleForm.total_meney_2"(value){
            let num=1
            if(this.ruleForm.total_num){
                num=this.ruleForm.total_num
            }
            if(!value){
                 this.showRed_border2=false
            }
            this.ruleForm.total_amount=value*num
        },
        "ruleForm.total_num"(value){
            if(this.ruleForm.envelope_type==2){
                this.ruleForm.total_amount=value*this.ruleForm.total_meney_2
            }
        }
    },
    filters:{
        myFixed(value){
            if(!value){
                return "0.00"
            }else{
                var num = new Number(value)
                return num.toFixed(2)
            }
        }
    },
    methods: {
        inputBlur1(){
            if(!this.ruleForm.total_meney_1){
                this.showRed_border1=true
            }else{
                if(this.ruleForm.total_num && this.ruleForm.total_meney_1/this.ruleForm.total_num<1){
                    this.showRed_border1=true
                }else{
                    this.showRed_border1=false
                }
            }
        },
        inputBlur2(){
            if(!this.ruleForm.total_meney_2){
                this.showRed_border2=true
            }else{
                if(this.ruleForm.total_meney_2/this.ruleForm.total_num<1){
                    this.showRed_border2=true
                }else{
                    this.showRed_border2=false
                }
            }
        },
        async submitForm(formName) {
        this.$refs[formName].validate( async(valid) => {
            if(!this.ruleForm.total_meney_1 && this.ruleForm.envelope_type=='1'){
                this.showRed_border1=true
                return
            }
            if(this.ruleForm.envelope_type=='1' && this.ruleForm.total_meney_1/this.ruleForm.total_num<1){
                this.showRed_border1=true
                return
            }
            if(!this.ruleForm.total_meney_2 && this.ruleForm.envelope_type=='2'){
                this.showRed_border2=true
                return
            }
            
            if (valid) {
                let startTime=this.$moment(this.ruleForm.start_time).unix()
                let endTime=this.$moment(this.ruleForm.end_time).unix()
                if(startTime>endTime){
                    this.$message.warning(this.$t('companyCatalog1v1Error9'))
                    return
                }
                // 发红包的时间检查
                let paramsTime={
                    related_id:this.related_id,
                    meeting_id:this.$store.state.meeting_id,
                    time_low:startTime,
                    time_high:endTime,
                }
                if(this.drawerType=='edit'){
                    paramsTime.red_envelope_cash_id=this.drawerValue.id
                }

                let checkTime = await this.$store.dispatch("baseConsole/check_red_envelope_time",paramsTime);
                if(checkTime && !checkTime.success){
                    this.$message.warning(this.$t('timerTitle'))
                    return
                }
                
                // // 发红包的余额检查
                // let paramsBalance={
                //     related_id:this.related_id,
                //     meeting_id:this.$store.state.meeting_id,
                //     moneys:this.ruleForm.total_amount,
                //     account_type:2 //账户类型：2现金3业务
                // }
                // let checkBalance = await this.$store.dispatch("baseConsole/check_balance",paramsBalance);
                // if(checkBalance && !checkBalance.success){
                //     this.$message.warning('余额不足')
                //     return
                // }

                // 提交
                let params={
                    related_id:this.related_id,
                    // red_envelope_cash_id
                    topic_zh:this.ruleForm.topic_zh,
                    topic_en:this.ruleForm.topic_en,
                    start_time:startTime,
                    end_time:endTime,
                    envelope_type:this.ruleForm.envelope_type,
                    total_num:this.ruleForm.total_num,
                    total_amount:this.ruleForm.total_amount*1*100,
                    currency:this.ruleForm.currency,
                    limit_receive_num:this.ruleForm.limit_receive_num||1,
                    is_abort:0,
                    meeting_id:this.$store.state.meeting_id,
                }
                if(this.ruleForm.envelope_type==2){
                    params.total_amount=this.ruleForm.total_meney_2*1*100
                }

                if(this.drawerType=='edit'){
                    params.red_envelope_cash_id=this.drawerValue.id
                }
                let upload= await this.$store.dispatch("baseConsole/cash_red_envelope",params);
                if(upload && upload.success){
                    this.$message.success(this.$t('Createdsuccessfully'))
                    this.$emit('upLoadSuccess',{});
                }else{

                    // INSUFFICIENT_BALANCE: 'insufficient balance',余额不足
                    // ACCOUNT_NOT_FOUND: 'account not exist',账号不存在
                    // RED_ENVELOPE_NOT_FOUND: 'red envelope not found',红包不存在
                    // ALREADY_USED: 'already used',红包已经开始使用不准再修改

                    if(upload.message=='insufficient balance'){
                        this.$message.warning(this.$t('balanceTitle'))
                    }else if(upload.message=='account not exist'){
                        this.$message.warning(this.$t('account_not_exist'))
                    }else if(upload.message=='red envelope not found'){
                        this.$message.warning(this.$t('envelope_not_found'))
                    }else if(upload.message=='already used'){
                        this.$message.warning(this.$t('used_cant_edit'))
                    }else{
                        this.$message.warning(this.$t('Failed'))
                    }
                }
            } else {
                return false;
            }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.$emit('drawerClose', {});
      }
    }
}
</script>
<style lang="less" scoped>
    .dramerbox {
        padding-bottom: 90px;
        .drawerFooter {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 30px;
            width: 100%;
            background: #fff;
        }
    }
    .demo-ruleForm{
        padding: 0px 24px;
    }
    .timePicker{
        display: flex;
        .pick_item_width{
            width: 300px;
        }
    }
    .usingLabel {
        width: 120px;
        text-align: right;
        padding-right: 12px;
        line-height: 38px;
        color: #606266;
        &::before {
            content: "*";
            color: #f56c6c;
            margin-right: 4px;
        }
    }
    .using {
        width: 420px;
        margin-bottom: 10px;
    }
    /deep/ .borderRed{
        .el-input__inner{
            border: 1px solid #F56C6C;
        }
    }
    .tips {
        color: #999;
        font-size: 12px;
        line-height: 20px;
        padding-left: 120px;
    }
    .tips_padding{
            padding-left: 150px;
    }
</style>