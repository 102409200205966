<template>
  <div class="cashBox">
    <div class="myborder">
      <div class="detailHeader">
        <div class="h_left">
          <div class="textOverflow" style="max-width: 240px" :title="hb_info.topic_en|priorFormat(hb_info.topic_zh,LOCALE)">{{hb_info.topic_en|priorFormat(hb_info.topic_zh,LOCALE)}}</div>
          <div v-if="hb_info.envelope_type==1">{{$t('pin')}}</div>
        </div>
        <div class="h_right">
          <div>
            <!-- 数量 -->
            <p class="num">{{$t('quantity2')}}：</p>
            <!-- 金额 -->
            <p class="price">{{page_currency}}：</p>
          </div>
          <div>
            <div>
              <!-- 目标发放 -->
              <p class="lable">{{$t('targetRelease')}}</p>
              <p class="p_num">{{ hb_info.total_num | textFormat(LOCALE) }}</p>
              <p class="p_num">{{ hb_info.total_amount | moneyFormat}}</p>
            </div>
            <div>
              <!-- 已领取 -->
              <p class="lable">{{$t('received')}}</p>
              <p class="p_num">{{ hb_info.geted_num | textFormat(LOCALE)}}</p>
              <p class="p_num">{{ hb_info.geted_amount| moneyFormat}}</p>
            </div>
          </div>
        </div>
      </div>
      <div style="min-height: 500px;background: #fff;">
        <pageTitle :title="$t('CollectionList')" :isBorder='true'></pageTitle>
      <div style="margin: 20px 0 12px">
        <searchBoxDetail @commponentSearch="commponentSearch"></searchBoxDetail>
      </div>
      <div class="tableBlock"  style="padding-bottom:15px">
        <el-table
          :data="tableData"
          style="width: 100%"
          :height="tableHeight"
          v-tableHeight="{ bottomOffset: 74 }"
          :tableLoading="loading"
        >
        <!-- 红包编号 -->
          <el-table-column :label="$t('redBackNumber')" prop='receive_no'></el-table-column>
          <!-- 领取人 -->
          <el-table-column :label="$t('recipients')">
            <template slot-scope="scope" >
              <userMsgPopover 
                :user_name_zh='scope.row.user_name_zh' 
                :user_name_en="scope.row.user_name_en" 
                :user_id="scope.row.user_id"
              ></userMsgPopover>
            </template>
          </el-table-column>
          <!-- 公司 -->
          <el-table-column :label="$t('Company_n')">
            <div slot-scope="scope">
              {{ scope.row.company_name_en | priorFormat(scope.row.company_name_zh, LOCALE) }}
            </div>
          </el-table-column>
          <!-- 金额 -->
          <el-table-column :label="page_currency">
            <div slot-scope="scope">
              {{ scope.row.amount | moneyFormat }}
            </div>
          </el-table-column>
          <!-- 领取时间 -->
          <el-table-column :label="$t('collectionTime')">
            <div slot-scope="scope">
              {{ scope.row.receive_time | secondFormat('LLLL') }}
            </div>
          </el-table-column>
        </el-table>
      </div>
      <div class="paginationBlock">
          <el-pagination
            @current-change="handleChange"
            :page-size="10"
            :current-page.sync="currentPage"
            layout="total, prev, pager, next"
            :total="total"
          >
          </el-pagination>
      </div>

      </div>
      
    </div>
    
  </div>
</template>
<script>
import pageTitle from "~bac/components/common/pageTitle";
import searchBoxDetail from "~bac/components/conference/redEnvelopeAdmin/searchBoxDetail";
import drawerCash from "~bac/components/conference/redEnvelopeAdmin/drawerCash";
import userMsgPopover from "~/baseComponents/card/userMsgPopover"
export default {
  components: {
    pageTitle,
    searchBoxDetail,
    drawerCash,
    userMsgPopover
  },
  data() {
    return {
      page_currency:"",
      related_id: "",
      envelope_id:"",
      hb_info:{},
      query_search:{
        receive_no:"",
        user_name:"",
        company_name:"",
        email:"",
      },
      tableHeight: 100,
      tableData: [],
      total: 0,
      currentPage:1,
      loading: false,
    };
  },
  mounted() {
    this.related_id = this.USER_INFO.company_id
    this.envelope_id = this._decode(this.$route.query.parameter).envelope_id
    console.log('envelope_id',this.envelope_id);
    this.getInfo();
    this.getList();
  },
  methods: {
    // 获取红包详情
    async getInfo() {
      let params = {
        envelope_id: this.envelope_id, //val.envelope_id,
        envelope_type: 2,//红包类型，1=业务红包 2=现金红包
      };
      let data = await this.$store.dispatch("baseConsole/getCensusNum",params);
      this.hb_info=data.data

      
      if(data.data.currency==1){
        this.page_currency=this.$t('amount')+'(￥)'
      }else{
        this.page_currency=this.$t('amount')+'($)'
      }
     


    },
    // 获取红包列表
    async getList() {
      let params = {
        company_id: this.related_id,
        receive_no:this.query_search.receive_no,
        user_name:this.query_search.user_name,
        company_name:this.query_search.company_name,
        envelope_id:this.envelope_id,
        email:this.query_search.email,
        mobile:this.query_search.mobile,
        start:this.currentPage*1-1,
        length:10,
      };
      this.loading = true;
      let list = await this.$store.dispatch("baseConsole/cash_red_envelopeList", params);
      this.tableData = list.data.data
      this.loading = false;
      this.total = list.data.count;
    },
    // 子组件的搜索
    commponentSearch(e) {
      this.query_search=e
      this.currentPage=1
      this.getList()
    },
    // 分页
    handleChange(e) {
      this.currentPage=e
      this.getList()
    },
  },
};
</script>
<style lang="less" scoped>
.myborder {
  .detailHeader{
    display: flex;
    justify-content: space-between;
    padding: 33px;
    height: 162px;
    background: #FFFFFF;
    margin-bottom: 12px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.19);

    .h_left{
      display: flex;
      div:first-child{
        height: 28px;
        font-size: 20px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 28px;
        padding-right:16px;
      }
      div:nth-child(2){
        min-width: 30px;
        height: 30px;
        background: #FFA536;
        padding: 0px 7px;
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        font-weight: 800;
        color: #FFFFFF;
      }
    }
    .h_right{
      display: flex;
      div:first-child{
        .num{
          margin-top: 22px;
          height: 22px;
          font-size: 14px;
          font-weight: 400;
          color: #298DF8;
          line-height: 22px;
        }
        .price{
          margin-top:17px;
          height: 22px;
          font-size: 14px;
          font-weight: 400;
          color: #DD9740;
          line-height: 22px;
        }
      }
      div:nth-child(2){
        display: flex;
        div{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          width: 250px;
          .p_num{
            height: 32px;
            font-size: 24px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 32px;
          }
        }
        div:first-child{
          border-right:1px solid #E8E8E8;
        }
      }
    }
  }
}
</style>